import logo from "./logo.svg";
import "./App.css";
import ProjectCard from "./components/project-card";
import ExperienceCard from "./components/experience-card";

function App() {
  return (
    <div className="App">
      <div id="introduction">
        <video id="background-video" autoPlay loop muted>
          <source
            src="./images/general-images/background-video.mp4"
            type="video/mp4"
          />
        </video>

        <img
          id="avatar-image"
          src="./images/general-images/avatar.png"
          alt="profile-image"
          width={350}
        />

        <div className="header-items">
          <h1 id="name-header" style={{ color: "whitesmoke" }}>
            GEENETH KULATUNGE
          </h1>
          <h1 id="info-header" style={{ color: "whitesmoke" }}>
            Software Developer
          </h1>
          <div id="header-image">
            <img
              src="/images/general-images/title-svg.svg"
              className="technologies"
              alt="technologies"
              width={500}
            />
          </div>
          <div id="socials">
            <div id="social-links">
              <a
                href="https://www.linkedin.com/in/geeneth-kulatunge/"
                target="_blank"
              >
                <img
                  id="linkedin-image"
                  src="/images/general-images/linkedin-logo-white.png"
                  alt="linkedin"
                  height={35}
                />
              </a>

              <a href="https://github.com/Geeneth" target="_blank">
                <img
                  id="github-image"
                  src="/images/general-images/github-logo-white.png"
                  alt="linkedin"
                  height={35}
                />
              </a>
              <a href="#resume" className="resume-title-class">
                <h3 id="resume-title" style={{ color: "whitesmoke" }}>
                  Resume
                </h3>
              </a>
            </div>
          </div>
          <div className="scroll-icon">
            <a href="#information">
              <img
                src="/images/general-images/scroll-icon-white.png"
                alt="linkedin"
                height={40}
              />
            </a>
          </div>
        </div>
      </div>

      <div id="information">
        <div id="information-container">
          <ExperienceCard
            side="left"
            title="Morgan Stanley"
            role="Software Developer Intern"
            date="May 2023 - August 2023"
            description=""
            contribution="I built the entire project using ReactJS. The 3D models were created and will, in the future be created by the team of artists I gathered. I constructed a system to retrieve and dynamically render each model into a card component which gives the user a preview of the product, its name, a small description of the model and a method of downloading it. I hosted the web application using Firebase."
            points={[
              "Improved rendering speeds of front end components by 60% after spearheading the migration of internal technology stacks and assisting the creation of faster backend services.",
              "Recovered 100+ records of user data by implementing a new job retrieval tool for the department dashboard.",
              "Resolved 120+ complaints related to production code by addressing underlying resource sharing complexities.",
            ]}
            technologies={["TypeScript", "Angular", "Java", "SQL", "Spring Boot"]}
          />
          <ExperienceCard
            side="left"
            title="Morgan Stanley"
            role="Software Developer Intern"
            date="January 2023 - April 2023"
            description=""
            contribution="I built the entire project using ReactJS. The 3D models were created and will, in the future be created by the team of artists I gathered. I constructed a system to retrieve and dynamically render each model into a card component which gives the user a preview of the product, its name, a small description of the model and a method of downloading it. I hosted the web application using Firebase."
            points={[
              "Improved consistency of user interface for the entitlement system by diagnosing 15+ logical errors in codebase.",
              "Enhanced accuracy of job processing data by 50% by eliminating calculation-related errors.",
              "Increased speed of accessibility of data by 30% by building an API endpoint to facilitate cross team data sharing"
            ]}
            technologies={["TypeScript", "Angular", "Java", "SQL"]}
          />
        </div>
        {/* <img id="avatar-image-2" src="./images/general-images/avatar.png" alt="profile-image" width={350} /> */}
        <hr className="line-class"/>
        <div id="projects">
          <ProjectCard
            side="left"
            title="Fighter Focus"
            description="Fighter Foucs is a multiplayer sports betting game primarily focusing on the UFC. The game allows users to create an account and bet on upcoming fights. The users can also view their betting history and their current bets. The game is currently in its early stages and will be updated regularly."
            contribution="I built the project using NextJS, NestJS and MongoDB. The game is currently in a closed beta with real players playing the game and providing feedback. The game will continue to grow and will be released to the public in the near future."
            links={[
              "https://fighter-focus-geeneth.vercel.app"
            ]}
            link_names={["WebApp"]}
            image_list={[
              "./images/fighterfocus/home-page.png",
              "./images/fighterfocus/cards.png",
              "./images/fighterfocus/tables.png",
            ]}
            technologies={["NextJS", "NestJS", "MongoDB", "Javascript", "TypeScript", "Render", "Vercel"]}
          />
          <ProjectCard
            side="left"
            title="Artisfri"
            description="Artisfri is a platform for artists and creators to purchase affordable art assets to use in their projects. What sets us apart is that we do not use the system of all users being able to upload assets. We have a panel of certified artists that create the models, this assures consistent quality and effective customer support. We are far from reaching our full potential yet, however, we are working hard to get there and therefore our website will constantly be updated."
            contribution="I built the entire project using ReactJS. The 3D models were created and will, in the future be created by the team of artists I gathered. I constructed a system to retrieve and dynamically render each model into a card component which gives the user a preview of the product, its name, a small description of the model and a method of downloading it. I hosted the web application using Firebase."
            links={[
              "https://artisfri.com/",
              "https://github.com/Geeneth/artisfri",
              "https://www.linkedin.com/company/artisfri/posts/?feedView=all&viewAsMember=true",
            ]}
            link_names={["Website", "GitHub", "LinkedIn"]}
            image_list={[
              "./images/artisfri/introduction.png",
              "./images/artisfri/new-arrivals.png",
              "./images/artisfri/database.png",
            ]}
            technologies={["HTML", "CSS", "JavaScript", "React"]}
          />
          <ProjectCard
            side="left"
            title="PassGuard"
            description="Our team of four was inspired to create this password creation and security measurement website after participating in a workshop on the best practices of cybersecurity. We realized the importance of strong, secure passwords and wanted to create a tool that would make it easy for people to create complex passwords and check their level of security."
            contribution="I added a layer of complexity to the password generation algorithm by eliminating the possibility of passwords having predictable keypaths. I implemented the system by essentially splitting the standard keyboard into 4 quadrants and randomly selecting a quadrant and then selecting a key from that quadrant. I also built the local application using ElectronJS. Furthermore, I created a Chrome Extension that allows the user to generate a password and check the security level of one directly from the browser."
            links={[
              "http://passguard.tech/?i=1",
              "https://github.com/zitatorocsik/passGuardian",
              "https://devpost.com/software/passguard-ae34o6#updates",
            ]}
            link_names={["Website", "GitHub", "DevPost"]}
            image_list={[
              "./images/pass-guard/pass-guard-1.png",
              "./images/pass-guard/pass-guard-2.png",
              "./images/pass-guard/pass-guard-4.png",
            ]}
            technologies={[
              "HTML",
              "CSS",
              "JavaScript",
              "React",
              "ElectronJS",
              "Chrome Extension",
            ]}
          />
          <ProjectCard
            side="left"
            title="HealthSimple"
            description="HealthSimple is a desktop widget that delivers motivation messages, as well as reminders for hydration and physical activity. The app was built to help users that work long hours at a computer to stay healthy and motivated. The app was created entirely in Python using the Tkinter library. HealthSimple sends the user a notification every few minutes to remind them to drink water and to take a break from the computer. The users have the ability to customize the settings of the notifications and view its history. We also included a bank of motvational messages that the user will receive at random intervals."
            technologies={["Python", "Tkinter"]}
            contribution="I primarily worked on the main functionality of the application including the notification system. I managed to implement multiple threads to run the application in the background, allowing the notifications to be delivered at any time. I also worked on the front-end development of the widget alongside my teammate. Furthermore, I created the bank of messages and the functionality to send them at random intervals. I also worked on the final look of the app including the background image and logo."
            links={[
              "https://devpost.com/software/healthsimple-hlp28w",
              "https://github.com/angelazanina/HealthSimple",
              "https://www.linkedin.com/posts/geeneth-kulatunge_had-an-amazing-experience-working-with-arthur-ugcPost-6937180049192235008-OEK9?utm_source=share&utm_medium=member_desktop",
            ]}
            link_names={["DevPost", "GitHub", "LinkedIn"]}
            image_list={[
              "./images/healthsimple/healthsimple-logo.png",
              "./images/healthsimple/healthsimple-gui.png",
              "./images/healthsimple/healthsimple-demo.png",
            ]}
          />
          <ProjectCard
            side="left"
            title="OneStep"
            description="OneStep is a proof of concept of a mobile application that aims to allow individuals to track their personal carbon footprint and reduce it by providing sustainable suggestions for their lifestyles. OneStep was my team's submission to Upstart, an entrepreneurship competition held by the Gina Cody School Entrepreneurship Society. The app would be able to track a vehicle's CO2 emissions, as well as the user's carbon footprint from their daily activities. The app would also provide suggestions to reduce the user's carbon footprint. A potential feature of the app would be to allow users to connect their account to their HydroQuebec account to allow automatic tracking of their electricity usage."
            contribution="I handled the aesthetical aspect of the application entirely. I created the logo and the UI design of the app using Figma. I also worked on forging ideas for the application and assisted in creating the initial draft of the business plan."
            links={[
              "https://docs.google.com/presentation/d/10pCywOPiyGT3BW6UXAQ86XUr4tq88AaaITc_HNUaCmE/edit?usp=sharing",
            ]}
            link_names={["Presentation"]}
            image_list={[
              "./images/one-step/one-step-1.png",
              "./images/one-step/one-step-2.png",
              "./images/one-step/one-step-3.png",
            ]}
            technologies={["Figma"]}
          />
          <ProjectCard
            side="left"
            title="PawPark"
            description="Pawpark is a website that allows users to put pets up for adoption or adopt one themselves. Users can create their own accounts and browse through a databse of pets while also learning some fun facts about cats and dogs. PawPark is an academic project I built for my web development course."
            contribution="I built the entire project using the technologies mentioned above. Received a 100% on the project. The data is stored in text files as requested by the professor. I built a system using cookies to make sure that the user has to be logged in to be able to access most of the website. Once logged in, the user can put a pet up for adoption, thereby poppulating the text file of pets and also browse to adopt a pet. I constructed a filtering algorithm using PHP that searches the text file of pets for what kind of pet the user is looking for."
            links={["https://github.com/Geeneth/PawPark-Pet-Website"]}
            link_names={["GutHub"]}
            image_list={[
              "./images/pawpark/pawpark-1.png",
              "./images/pawpark/pawpark-2.png",
              "./images/pawpark/pawpark-3.png",
            ]}
            technologies={["HTML", "CSS", "PHP", "JavaScript"]}
          />
          <ProjectCard
            side="left"
            title="CSV to HTML Converter"
            description="An application to convert any formatted CSV file to a table in a HTML file using Java."
            contribution="Used File Input/Output to read any CSV file the user requests and convert it to an HTML file using a plethora of methods. These methods are noted down in the JavaDoc file."
            links={["./images/csv-to-html/doc/index.html"]}
            link_names={["JavaDoc"]}
            image_list={[
              "./images/csv-to-html/csv-to-html-1.png",
              "./images/csv-to-html/csv-to-html-2.png",
              "./images/csv-to-html/csv-to-html-3.png",
            ]}
            technologies={["Java"]}
          />
        </div>

        {/* testing PDF preview */}
        <div id="resume">
          <object
            data="/resume/Geeneth_Kulatunge_Resume_09_2024.pdf"
            type="application/pdf"
            width="60%"
            height="1140vh"
          >
            {/* <p>
              Alternative text - include a link{" "}
              <a href="/resume/Geeneth_Kulatunge_Resume.pdf">to the PDF!</a>
            </p> */}
          </object>
        </div>
      </div>
    </div>
  );
}

export default App;

import "./project-card-left.css";
import "./project-card-right.css";

function ProjectCard(props) {
  if (props.side === "left") {
    return (
      <div className="project-card-left">
        <h1 className="project-card-left-header">{props.title}</h1>
        <p className="project-card-description">{props.description}</p>
        <div className="tech-links-area">
          <ul className="project-links">
            {props.technologies.map((technology, index) => (
              <li>
                <p>{props.technologies[index]}</p>
              </li>
            ))}
          </ul>
        </div>
        <p className="project-card-contribution">{props.contribution}</p>
        <div className="links-area">
          <ul className="project-links">
            {props.links.map((link, index) => (
              <li>
                <a href={link} target="blank_">{props.link_names[index]}</a>
              </li>
            ))}
          </ul>
        </div>
        <div className="project-card-images">
          <ul className="project-images-list">
            {props.image_list.map((image, index) => (
              <li>
                <img src={props.image_list[index]} alt="github" width={350} />
              </li>
            ))}
          </ul>
        </div>
      </div>
    );
  }

  return (
    <div className="project-card-right">
      <h1 className="project-card-right-header">{props.title}</h1>
      <p className="project-card-right-description">{props.description}</p>
      <p className="project-card-right-description">{props.contribution}</p>
      <div className="links-area-right">
        <ul className="project-links">
          {props.links.map((link, index) => (
            <li>
              <a href={link}>{props.link_names[index]}</a>
            </li>
          ))}
        </ul>
      </div>

      <div className="project-card-images">
        <ul className="project-images-list">
          {props.image_list.map((image, index) => (
            <li>
              <img src={props.image_list[index]} alt="project-image" width={350} />
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}

export default ProjectCard;
